/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Variable {
  readonly id?: number;
  varType: Variable.VarTypeEnum;
  docType: Variable.DocTypeEnum;
  algo?: Variable.AlgoEnum;
  name: string;
  config?: any;
  identifier?: string;
  onePerDoc?: boolean;
  optional?: boolean;
  multiplicity?: boolean;
  valueMapping?: any;
  confidenceThreshold?: number;
  parent?: number;
  dependsOn?: Array<number>;
}
export namespace Variable {
  export type VarTypeEnum = 'NUM' | 'STR' | 'DATE' | 'BOOL' | 'ADDR';
  export const VarTypeEnum = {
    NUM: 'NUM' as VarTypeEnum,
    STR: 'STR' as VarTypeEnum,
    DATE: 'DATE' as VarTypeEnum,
    BOOL: 'BOOL' as VarTypeEnum,
    ADDR: 'ADDR' as VarTypeEnum,
  };
  export type DocTypeEnum =
    | 'COMP'
    | 'ACTE'
    | 'TITRE'
    | 'EHYP'
    | 'CAD'
    | 'CAD-REDUIT'
    | 'RU'
    | 'PEB'
    | 'DECOM'
    | 'CI'
    | 'REG'
    | 'ELEC'
    | 'BDES'
    | 'MAIN'
    | 'GARAN'
    | 'VENTE'
    | 'DEPOT'
    | 'BAIL'
    | 'CERTI'
    | 'OVAM'
    | 'CRED'
    | 'ACTE_CRED'
    | 'EH_COMPL'
    | 'EH_DEMANDE'
    | 'OTHER'
    | 'IBGE'
    | 'ARTICLE90'
    | 'COMP_SIGNE'
    | 'ACTE_MAIN'
    | 'OFFRE'
    | 'MAP'
    | 'WATERTOETS'
    | 'ASBEST';
  export const DocTypeEnum = {
    COMP: 'COMP' as DocTypeEnum,
    ACTE: 'ACTE' as DocTypeEnum,
    TITRE: 'TITRE' as DocTypeEnum,
    EHYP: 'EHYP' as DocTypeEnum,
    CAD: 'CAD' as DocTypeEnum,
    CADREDUIT: 'CAD-REDUIT' as DocTypeEnum,
    RU: 'RU' as DocTypeEnum,
    PEB: 'PEB' as DocTypeEnum,
    DECOM: 'DECOM' as DocTypeEnum,
    CI: 'CI' as DocTypeEnum,
    REG: 'REG' as DocTypeEnum,
    ELEC: 'ELEC' as DocTypeEnum,
    BDES: 'BDES' as DocTypeEnum,
    MAIN: 'MAIN' as DocTypeEnum,
    GARAN: 'GARAN' as DocTypeEnum,
    VENTE: 'VENTE' as DocTypeEnum,
    DEPOT: 'DEPOT' as DocTypeEnum,
    BAIL: 'BAIL' as DocTypeEnum,
    CERTI: 'CERTI' as DocTypeEnum,
    OVAM: 'OVAM' as DocTypeEnum,
    CRED: 'CRED' as DocTypeEnum,
    ACTECRED: 'ACTE_CRED' as DocTypeEnum,
    EHCOMPL: 'EH_COMPL' as DocTypeEnum,
    EHDEMANDE: 'EH_DEMANDE' as DocTypeEnum,
    OTHER: 'OTHER' as DocTypeEnum,
    IBGE: 'IBGE' as DocTypeEnum,
    ARTICLE90: 'ARTICLE90' as DocTypeEnum,
    COMPSIGNE: 'COMP_SIGNE' as DocTypeEnum,
    ACTEMAIN: 'ACTE_MAIN' as DocTypeEnum,
    OFFRE: 'OFFRE' as DocTypeEnum,
    MAP: 'MAP' as DocTypeEnum,
    WATERTOETS: 'WATERTOETS' as DocTypeEnum,
    ASBEST: 'ASBEST' as DocTypeEnum,
  };
  export type AlgoEnum =
    | 'CAMEMBERT'
    | 'GPT'
    | 'REGEX'
    | 'BBOX'
    | 'GPT_ID'
    | 'GPT_ENTITIES'
    | 'EXISTS'
    | 'SUM'
    | 'DECOMPTE'
    | 'FUNCTION'
    | 'CUSTOM'
    | 'GPT_VISION'
    | 'MAP';
  export const AlgoEnum = {
    CAMEMBERT: 'CAMEMBERT' as AlgoEnum,
    GPT: 'GPT' as AlgoEnum,
    REGEX: 'REGEX' as AlgoEnum,
    BBOX: 'BBOX' as AlgoEnum,
    GPTID: 'GPT_ID' as AlgoEnum,
    GPTENTITIES: 'GPT_ENTITIES' as AlgoEnum,
    EXISTS: 'EXISTS' as AlgoEnum,
    SUM: 'SUM' as AlgoEnum,
    DECOMPTE: 'DECOMPTE' as AlgoEnum,
    FUNCTION: 'FUNCTION' as AlgoEnum,
    CUSTOM: 'CUSTOM' as AlgoEnum,
    GPTVISION: 'GPT_VISION' as AlgoEnum,
    MAP: 'MAP' as AlgoEnum,
  };
}
