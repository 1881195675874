/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MyConstant } from './myConstant';
import { MyVariable } from './myVariable';
import { RecursiveField } from './recursiveField';
import { Status } from './status';
import { Tag } from './tag';

export interface RuleOfFolder {
  readonly id?: number;
  ref: string;
  varLeft?: MyVariable;
  operator: RuleOfFolder.OperatorEnum;
  varRight?: MyVariable;
  constant?: MyConstant;
  rulestatus?: Status;
  triggeredRules?: RecursiveField;
  readonly tags?: Array<Tag>;
  readonly explanation?: string;
  fetchRank?: number;
  folderModes?: Array<RuleOfFolder.FolderModesEnum>;
  readonly inconsistent?: boolean;
  popup?: boolean;
  readonly popupText?: string;
  varLeftConfidenceThreshold?: number;
  varRightConfidenceThreshold?: number;
  sortKey?: number;
  displayOperator?: RuleOfFolder.DisplayOperatorEnum;
}
export namespace RuleOfFolder {
  export type OperatorEnum = 'GT' | 'LT' | 'EQ' | 'NE' | 'SIM' | 'GTE' | 'LTE' | 'IN' | 'NIN' | 'EXI' | 'NULL';
  export const OperatorEnum = {
    GT: 'GT' as OperatorEnum,
    LT: 'LT' as OperatorEnum,
    EQ: 'EQ' as OperatorEnum,
    NE: 'NE' as OperatorEnum,
    SIM: 'SIM' as OperatorEnum,
    GTE: 'GTE' as OperatorEnum,
    LTE: 'LTE' as OperatorEnum,
    IN: 'IN' as OperatorEnum,
    NIN: 'NIN' as OperatorEnum,
    EXI: 'EXI' as OperatorEnum,
    NULL: 'NULL' as OperatorEnum,
  };
  export type FolderModesEnum = 'COMPROMIS' | 'VENTE' | 'CREDIT' | 'MANDAT_HYPO' | 'MAIN_LEVEE';
  export const FolderModesEnum = {
    COMPROMIS: 'COMPROMIS' as FolderModesEnum,
    VENTE: 'VENTE' as FolderModesEnum,
    CREDIT: 'CREDIT' as FolderModesEnum,
    MANDATHYPO: 'MANDAT_HYPO' as FolderModesEnum,
    MAINLEVEE: 'MAIN_LEVEE' as FolderModesEnum,
  };
  export type DisplayOperatorEnum = 'GT' | 'LT' | 'EQ' | 'NE' | 'SIM' | 'GTE' | 'LTE' | 'IN' | 'NIN' | 'EXI' | 'NULL';
  export const DisplayOperatorEnum = {
    GT: 'GT' as DisplayOperatorEnum,
    LT: 'LT' as DisplayOperatorEnum,
    EQ: 'EQ' as DisplayOperatorEnum,
    NE: 'NE' as DisplayOperatorEnum,
    SIM: 'SIM' as DisplayOperatorEnum,
    GTE: 'GTE' as DisplayOperatorEnum,
    LTE: 'LTE' as DisplayOperatorEnum,
    IN: 'IN' as DisplayOperatorEnum,
    NIN: 'NIN' as DisplayOperatorEnum,
    EXI: 'EXI' as DisplayOperatorEnum,
    NULL: 'NULL' as DisplayOperatorEnum,
  };
}
